import '@/styles/globals.css';
import PageWithLayoutType from '@/types/pageWithLayout';
import {DefaultSeo} from 'next-seo';
import Script from 'next/script';
import SEO from '../next-seo.config';
import * as gtag from '../libs/gtags';

type AppLayoutProps = {
  Component: PageWithLayoutType;
  pageProps: any;
};

export default function App({Component, pageProps}: AppLayoutProps) {
  //레이아웃
  const Layout = Component.layout || ((children) => <>{children}</>);

  gtag.useGtag();
  return (
    <Layout>
      <style global jsx>{`
        html,
        body,
        body > div:first-child,
        div#__next,
        div#__next > div {
          height: 100%;
        }
      `}</style>
      {process.env.NODE_ENV !== 'development' && (
        <>
          {/* Global Site Tag (gtag.js) - Google Analytics */}
          <Script
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
          />
          <Script
            id="gtag-init"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${gtag.GA_TRACKING_ID}', {
                  page_path: window.location.pathname,
                });
              `,
            }}
          />
        </>
      )}
      <DefaultSeo {...SEO} />
      <Component {...pageProps} />
    </Layout>
  );
}
