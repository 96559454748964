import {DefaultSeoProps} from 'next-seo';
const config: DefaultSeoProps = {
  titleTemplate: '%s | 24시간 영업 고수익 알바 여성알바 유흥알바',
  additionalLinkTags: [
    {
      rel: 'icon',
      href: '/favicon.ico',
    },
    {
      rel: 'shortcut icon',
      href: '/favicon.ico',
    },
    {
      rel: 'manifest',
      href: '/manifest.json',
    },
  ],
  additionalMetaTags: [
    {
      name: 'theme-color',
      content: '#FFFFFF',
    },
    {
      name: 'keywords',
      content:
        '라인친구 24시간 영업 여성알바 유흥알바 고수익 밤알바 알바 밤일알바 여우알바 퀸알바',
    },
    {
      name: 'google-site-verification',
      content: 'N2vL03eqWw0FEGQdN25SvuArsAFWRKwZNRq6eLCllGg',
    },
  ],
  openGraph: {
    type: 'website',
    locale: 'ko_KR',
    // url:
    //title:
    siteName: '라인친구',
    // images: [
    //     {
    //       url: "카카오톡, 페이스북에에 링크 넣으면 올라올 이미지",
    //       width: 285,
    //       height: 167,
    //       alt: "이미지"
    //     }
    //   ]
  },
  twitter: {
    handle: '@handle',
    site: '@site',
    cardType: 'summary_large_image',
    // site:''
  },
};

export default config;
